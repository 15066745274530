<template>
  <div class="order">
    <div class="title">
      <h2>我的订单</h2>
      <div class="apply-invoice" @click="offerInvoice">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-invoice"></use>
        </svg>
        申请发票
      </div>
    </div>
    <div class="tabs">
      <div class="bar-list" :class="'selected' + selectedTab">
        <div class="tab-bar" :class="selectedTab === item.id ? 'selected' : ''" v-for="(item, index) in barList" @click="toggleTab(item)" :key="'center_order_tab_'+index">
          {{ item.text }}
        </div>
      </div>
      <div class="content" v-show="selectedTab==0">
        <ul v-if="orderList.length > 0">
          <li class="table-title">
            <p>产品名称</p>
            <p>数量</p>
            <p>单价（元）</p>
            <p>订单状态</p>
          </li>
          <li class="table-row" v-for="(item, index) in currentList" :key="'center_order_list_'+index">
            <p v-html="item.title || item.orderTitle || ''"></p>
            <p v-html="item.goodsNum"></p>
            <p v-html="item.realPayAmount/item.goodsNum"></p>
            <p v-html="item.realPayAmount && item.realPayAmount > 0 ? '已支付' : '待付款'"></p>
          </li>
          <li class="total">
            <p>
              共{{ orderTotal }}件产品，共计
              <span>￥{{ totalAmount }}</span>
            </p>
          </li>
        </ul>
        <h3 class="no-ticket" v-else>暂无订单</h3>
        <div class="page-wrapper" v-show="orderList.length > 0">
          <div class="order-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageLimit"
              :page-count="pageCount"
              :total="orderList.length"
              @current-change="togglePage"
              @prev-click="togglePrev"
              @next-click="toggleNext"
            ></el-pagination>
          </div>
          <div class="total-page">共{{ pageCount }}条</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sid, getUserOrder, getUserOrderUniversal } from "@/utils/api.js";

export default {
  props: {
    token: String,
  },
  data() {
    return {
      barList: [
        {
          text: "全部订单",
          id: 0,
        },
      ],
      selectedTab: 0,
      orderList: [],
      currentPage: 1, // 当前为第几页
      pageCount: 0, // 一共有几页
      pageLimit: 5,
      currentList: [], // 当前展示的订单列表
      totalAmount: 0, // 订单总金额
      orderTotal: 0,
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    offerInvoice() {
      this.$emit("openInvoice");
    },
    getOrder() {
      this.$http
        // .get(getUserOrder, {
        //   params: {
        //     token: this.token,
        //     sid: sid(this.global.year),
        //     choose: 1,
        //   },
        // })
        .get(getUserOrderUniversal, { params: {
          choose: 1,
          sid: sid(this.global.year),
          source: 1,
          type: 1, // 0,
          token: this.token,
        } })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log('order- ', data);
          if (data.status === "200") {
            this.orderList = data.data.resData;
            this.pageCount = Math.ceil(this.orderList.length / this.pageLimit);
            this.currentList = this.orderList.slice(0, this.pageLimit);
            this.computeNumberAmount();
          } else if (data.status === "1026" || data.status === "1008") {
            console.log("登录过期");
          } else {
            console.log("服务器繁忙，请重试");
          }
        });
    },
    // 
    toggleTab(item) {
      if(this.selectedTab != item.id){
        this.selectedTab = item.id;
      }
    },
    togglePage(page) {
      this.currentPage = page;
      this.currentList = this.orderList.slice(
        (this.currentPage - 1) * this.pageLimit,
        this.currentPage * this.pageLimit
      );
      this.computeNumberAmount();
    },
    togglePrev() {
      this.currentPage--;
      this.currentList = this.orderList.slice(
        this.currentPage * this.pageLimit,
        (this.currentPage + 1) * this.pageLimit
      );
      this.computeNumberAmount();
    },
    toggleNext() {
      this.currentPage++;
      this.currentList = this.orderList.slice(
        this.currentPage * this.pageLimit,
        (this.currentPage + 1) * this.pageLimit
      );
      this.computeNumberAmount();
    },
    computeNumberAmount() {
      this.totalAmount = this.currentList.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            parseFloat(currentValue.realPayAmount)
            //  * parseInt(currentValue.goodsNum)
          );
        },
        0
      );
      this.orderTotal = this.currentList.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.goodsNum);
      }, 0);
    },
  },
};
</script>

<style>
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 16px;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: var(--main-color);
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--main-color);
  color: #333;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  width: 36px;
  height: 36px;
}
.el-pager li {
  line-height: 36px;
}
</style>
<style lang="scss" scoped>
.order {
  padding: 33px;
  .title {
    display: flex;
    justify-content: space-between;
    h2 {
      margin-bottom: 25px;
      color: #5c5c5c;
      font-size: 24px;
      text-align: left;
      font-weight: 600;
    }
    .apply-invoice {
      margin-top: 4px;
      border: 1px solid #cccccc;
      color: #999999;
      font-size: 16px;
      font-weight: 500;
      padding: 0 15px;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      .icon {
        margin-right: 10px;
        width: 34px;
        height: 32px;
      }
    }
  }
  .tabs {
    .bar-list {
      border-bottom: 2px solid #efefef;
      display: flex;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 110px;
        height: 5px;
        bottom: 0;
        background: var(--main-color);
        transition: all linear 0.3s;
      }
      .tab-bar {
        padding: 23px 21px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        cursor: pointer;
        &.selected {
          color: #333333;
        }
      }
      &.selected0::before {
        left: 0;
      }
      &.selected1::before {
        left: 144px;
      }
    }
    .content {
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 40px;
        border: 1px solid #dddddd;
        border-bottom: none;
        .table-title {
          background: #f4f6f9;
          padding: 9px 0 9px 20px;
        }
        li {
          width: 100%;
          margin-bottom: 0;
          padding: 20px 0 20px 20px;
          border-bottom: 1px solid #dddddd;
          background-color: #ffffff;
          display: flex;
          p {
            font-size: 16px;
            color: #999999;
            &:nth-of-type(1) {
              width: 46%;
              text-align: left;
            }
            &:nth-of-type(2) {
              width: 18%;
              text-align: center;
            }
            &:nth-of-type(3) {
              width: 18%;
              text-align: center;
            }
            &:nth-of-type(4) {
              width: 18%;
              text-align: center;
            }
          }
          &.total {
            padding: 25px 40px 25px 0;
            p {
              width: 100%;
              text-align: right;
              span {
                font-size: 20px;
                color: #ff1a1a;
              }
            }
          }
        }
      }
      .no-ticket {
        padding-top: 100px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
      }
      .page-wrapper {
        display: flex;
        flex-direction: row-reverse;
        .total-page {
          margin-right: 10px;
          font-size: 14px;
          color: #999999;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .title {
      .apply-invoice {
        margin-top: 0;
      }
    }
  }
}
</style>